<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.rules.title }}</strong>
              </CCol>
              <CCol sm="6" class="align-self-center">
                <div class="text-sm-right mt-3 mt-sm-0">
                </div>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert
                color="danger"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>
            <v-server-table
                :columns="columns"
                url="/rules/list"
                :options="options"
                ref="myTable"
                @loaded="exports(self, 'Excel', module, 'Rules')"
            >
              <template #status="data">
                <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
              </template>
              <template #title="data">
                <span :title="data.row.title">{{
                    trimfunction(data.row.title, 20)
                  }}</span>
              </template>
              <template #actions="data">
                <CButton
                    color="success"
                    v-c-tooltip="$lang.buttons.general.crud.edit"
                    v-on:click="editTableRow(data.row.id)"
                    v-if="checkPermission('edit-rules')"
                >
                  <i class="fas fa-pencil-alt"></i>
                </CButton>
                <CButton
                    v-show="deleteShow"
                    v-c-tooltip="$lang.buttons.general.crud.delete"
                    color="danger"
                    v-on:click="deletePopup(data.row.id)"
                    v-if="checkPermission('delete-rules')"
                >
                  <i class="fas fa-trash"></i>
                </CButton>
              </template>
            </v-server-table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {modules, rules} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "RulesItem",
  mixins: [Mixin],
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  data() {
    return {
      deleteShow: false,
      json_data: [],
      json_label: {},
      json_title: "",
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      modules: [],
      activePage: 1,
      moduleMaster: modules,
      module: rules,
      columns: ["title", "actions"],
      data: [],
      options: {
        headings: {
          title: this.$lang.rules.table.title,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["title"],
        sortable: ["title"],
        filterable: ["title"],
      },
    };
  },
  mounted() {
    let self = this;
    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
      // this.setAlert(true);
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    self.adminId = localStorage.getItem("userId");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    editTableRow(item) {
      // this.$router.push({path: `/edit/${(item)}`})
      this.$router.push({name: "Edit Rules", params: {id: item}});
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    setAlert(value) {
      this.showAlert = value;
    },
    createTableRow() {
      this.$router.push({name: "Add Rule"});
    },

    rowClicked(item) {
      this.$router.push({path: `rules/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
